import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/students/Students.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Generate/Generate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/Layout/Layout.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/ModeChanger/ModeChanger.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/PreviewGenerate/PreviewGenerate.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/Money/Money.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/OurClients/OurClients.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/HowWorks/HowWorks.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/AICapabilities/AICapabilities.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/HowHelp/HowHelp.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/shared/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/TryFree/TryFree.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/YourPhone/YourPhone.module.scss");
